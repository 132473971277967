export const Crystallize = {
  ProductShape: {
    JOURNAZINE: "Journazine",
    PRODUCT: "Product",
  },
  PaymentMode: {
    PAYNOW_PAYLAH: "PayNow/PayLah!",
    STRIPE: "Stripe",
  },
  OrderMetaKey: {
    ORDER_NUMBER: "Order Number",
    PAYMENT_MODE: "Payment Mode",
    ONLINE_ACCESS_CODE: "Online Access Code"
  },
  CustomerAddressTypes: {
    DELIVERY: "delivery",
    OTHER: "other",
    BILLING: "billing",
  },
  Pipeline: {
    GENERAL: "General",
  },
  PipelineStage: {
    VOIDED: "Voided",
    PENDING_PAYMENT: "Pending Payment",
    PAID: "Paid",
    SHIPPED: "Shipped",
  },
}
